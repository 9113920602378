import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class LinkedInExtensionRequest {
  id: string;
  createdAt: StorageTimestamp;
  requesterId: string;
  introducerId: string;
  targetSlug: string;
  targetName: string;
  introType: string;
  message: string;
  chatId: string;
  nagsToSlack?: StorageTimestamp[];

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    requesterId: string,
    introducerId: string,
    targetSlug: string,
    targetName: string,
    introType: string,
    message: string,
    chatId: string,
    nagsToSlack?: StorageTimestamp[],
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.requesterId = requesterId;
    this.introducerId = introducerId;
    this.targetSlug = targetSlug;
    this.targetName = targetName;
    this.introType = introType;
    this.message = message;
    this.chatId = chatId;
    this.nagsToSlack = nagsToSlack;
  }
}

export const linkedInExtensionRequestDataConverter: FirestoreDataConverter<LinkedInExtensionRequest> =
  {
    toFirestoreModel: (model: LinkedInExtensionRequest) => {
      return {
        id: model.id,
        created_at: model.createdAt,
        requester_id: model.requesterId,
        introducer_id: model.introducerId,
        target_slug: model.targetSlug,
        target_name: model.targetName,
        intro_type: model.introType,
        message: model.message,
        chat_id: model.chatId,
        nags_to_slack: model.nagsToSlack,
      };
    },
    fromFirestoreModel: (data): LinkedInExtensionRequest => {
      return new LinkedInExtensionRequest(
        data.id,
        data.created_at,
        data.requester_id,
        data.introducer_id,
        data.target_slug,
        data.target_name,
        data.intro_type,
        data.message,
        data.chat_id,
        data.nags_to_slack,
      );
    },
  };

import {
  CollectionQueryType,
  OrderByType,
  WhereType,
  getCollection,
  useCollection,
  useDocument,
} from "../swr-firebase";
import { FirebaseError } from "firebase/app";
import { FirestoreDataConverter } from "@sequoiacap/shared/models";
import {
  PaginatedResult,
  ReadDocumentReturnType,
  ReadQueryReturnType,
} from "./types";
import { TrackEvent, track } from "~/utils/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { parseJSON } from "@sequoiacap/shared/utils/superjson";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { useGetStoredSWRValue } from "./network-cache-db";
import useNetworkInfo from "~/hooks/useNetworkInfo";
import useSWR, {
  BareFetcher,
  Key,
  Middleware,
  SWRConfiguration,
  SWRHook,
  mutate as staticMutate,
} from "swr";

export type ReadDocumentOptions<T> = {
  /**
   * If `true`, sets up a real-time subscription to the Firestore backend.
   */
  listen?: boolean;
  refresh?: boolean;
  returnNullOnError?: boolean;
  initialData?: T;
};

export const USE_INFINITE_QUERY = "%infinite%";

export enum CloudFunctionName {
  getCustomToken = "getCustomToken",
  getSearchToken = "getSearchToken",

  removeMemberFromGroupChat = "removeMemberFromGroupChat",
  addMemberToGroupChat = "addMemberToGroupChat",
  leaveGroupChat = "leaveGroupChat",

  leaveGroup = "leaveGroup",
  requestJoinGroup = "requestJoinGroup",
  cancelJoinGroup = "cancelJoinGroup",

  deletePost = "deletePost",
  deleteComment = "deleteComment",

  signupUnsignUpEvent = "signupUnsignUpEvent",

  generateDueDiligenceFile = "generateDueDiligenceFile",
}

export const FIREBASE_WRITE_TIMEOUT = 61000;

/**  When path is null, it is not ready to read data */
export function useReadDocument<T extends { id: string }>(
  path: string | null,
  converter: FirestoreDataConverter<T>,
  options: ReadDocumentOptions<T>,
): ReadDocumentReturnType<T> {
  const { data, error, loading, mutate } = useDocument<T>(path, {
    listen: options.listen,
    converter: converter,
    ignoreFirestoreDocumentSnapshotField: false,
    returnNullOnError: options.returnNullOnError,
    fallbackData:
      options.initialData && path
        ? {
            id: options.initialData.id,
            path: path,
            data: options.initialData,
            snapshot: undefined,
            s: "init",
          }
        : undefined,
    revalidateIfStale: options.refresh,
    revalidateOnFocus: options.refresh,
    revalidateOnReconnect: options.refresh,
    revalidateOnMount: options.initialData ? false : undefined,
    refreshInterval: options.initialData
      ? (newData) => {
          // When there is initialData, we want to refresh to get the latest data.
          // Once we get the latest data, we don't want to refresh again.
          if (newData?.s === "init" || newData?.s === "dc") {
            return 1000;
          }
          return 60 * 1000;
        }
      : undefined,
  });
  const revalidate = useCallback(
    async (clearResult = false) => {
      if (clearResult) {
        await mutate(undefined, clearResult);
      } else {
        await mutate();
      }
      return;
    },
    [mutate],
  );
  // const startTsRef = useRef(Date.now());
  // useEffect(() => {
  //   if (!loading) {
  //     const endTs = Date.now();
  //     const duration = endTs - startTsRef.current;
  //     console.log(`HEREEEEEEEE readDocument ${path} duration=${duration}`);
  //   }
  // }, [loading, path]);

  if (!path) {
    return { revalidate, loading: false };
  }
  if (loading) {
    return { revalidate, loading: true };
  } else if (error) {
    console.error(`[swr] useDocument path=${path} error`, error);
    return { revalidate, loading: false, error };
  } else if (data?.data) {
    return { revalidate, loading: false, data: data.data };
  }
  return {
    revalidate,
    loading: false,
    error: new Error(`Data not found path=${path}`),
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useReadQuery<T extends object>(
  collection: string | null,
  converter: FirestoreDataConverter<T>,
  limit?: number,
  orderBy?: OrderByType,
  where?: WhereType,
  options?: {
    listen?: boolean;
    refresh?: boolean;
    refreshInterval?: number;
  },
): ReadQueryReturnType<T> {
  const refresh = options?.refresh;
  const { data, error, isValidating, loading, mutate } = useCollection<T>(
    collection,
    {
      limit: limit,
      where: where,
      orderBy: orderBy,
      converter: converter,
      listen: options?.listen,
      ignoreFirestoreDocumentSnapshotField: false,
    },
    {
      // this lets us update the local cache + paginate without interruptions
      revalidateOnFocus: refresh,
      revalidateIfStale: refresh,
      revalidateOnReconnect: refresh,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: options?.refreshInterval,
    },
  );
  const revalidate = useCallback(
    async (clearResult = false) => {
      if (clearResult) {
        await mutate(undefined, clearResult);
      } else {
        await mutate();
      }
      return;
    },
    [mutate],
  );
  if (!collection) {
    return { revalidate, isValidating: false, loading: false };
  }
  if (loading) {
    return { revalidate, isValidating, loading: true };
  } else if (error) {
    return { revalidate, isValidating, loading: false, error };
  } else if (data) {
    const result = data
      ?.map((doc) => {
        // Don't skip if there are pending writes
        // if (doc.snapshot?.metadata.hasPendingWrites) {
        //   return undefined;
        // }
        if (doc.data && doc.id) {
          return doc.data;
        }
        return undefined;
      })
      .filter((doc): doc is T => !!doc);
    return { revalidate, loading: false, isValidating, data: result };
  }
  return {
    isValidating,
    revalidate,
    loading: false,
    error: new Error(`Data not found in query`),
  };
}

export async function callCloudFunction<T>(
  name: CloudFunctionName,
  params?: unknown,
): Promise<T> {
  const fn = httpsCallable(getFunctions(), name);
  const startTime = Date.now();
  try {
    const result = await fn(params);
    const endTime = Date.now();
    track(TrackEvent.callCloudFunction, {
      name,
      time: endTime - startTime,
    });
    return result.data as T;
  } catch (e) {
    track(TrackEvent.cloudFunctionError, {
      name,
      params: JSON.stringify(params ?? {}),
      error: JSON.stringify(e),
    });
    throw e;
  }
}

// TODO(kwyee): would be good to ensure the server has a host named X supports enumY functions, the client calls only enumY functions on host X and validate statically
export enum FunctionNameV2 {
  getPromptBoxes = "getPromptBoxes",
  sendBuilderEmail = "sendBuilderEmail",
  sendBuilderIntroDM = "sendBuilderIntroDM",
  getExperienceHubData = "getExperienceHubData",
  getMyExperienceHubs = "getMyExperienceHubs",
  createGroupChat = "createGroupChat",
  createDirectChat = "createDirectChat",
  countSimilarLookingDeals = "countSimilarLookingDeals",
  marketplaceLogin = "marketplaceLogin",
  retoolEmbedUrl = "retoolEmbedUrl",
  deletePost = "deletePost",
  deleteComment = "deleteComment",
  deleteEdgeStory = "deleteEdgeStory",
}

export function useGetFromCloudFunctionInSuperJSON<ResultType>(
  functionName?: FunctionNameV2,
  params?: Record<string, unknown>,
  options?: SWRConfiguration,
): ReadDocumentReturnType<ResultType> {
  const { data, error, revalidate } = useGetFromCloudFunction<string>(
    functionName,
    {
      format: "superjson",
      ...params,
    },
    options,
  );

  const parsedData = useMemo(() => {
    if (data) {
      return parseJSON<ResultType>(data);
    }
    return undefined;
  }, [data]);

  return {
    data: parsedData,
    error,
    loading: !error && !data,
    revalidate,
  };
}

export const swrPrecacheMiddleware: Middleware =
  <X>(useSWRNext: SWRHook) =>
  <ReturnType = X>(
    key: Key,
    fetcher: BareFetcher<ReturnType> | null,
    config: SWRConfiguration,
  ) => {
    const { updateCache } = useGetStoredSWRValue<ReturnType>(key, 0);
    const { data, error: swrError, ...swr } = useSWRNext(key, fetcher, config);
    const { online } = useNetworkInfo();

    // if both data and localResult are undefined or null, return whatever data is
    let error = swrError;
    if (
      error instanceof FirebaseError &&
      ((error.code === "functions/internal" && error.message === "internal") ||
        error.code === "functions/deadline-exceeded")
    ) {
      // This is for cloud function network errors
      error = undefined;
    } else if (error?.name === "RetryError") {
      // This is for algolia network errors
      error = undefined;
    } else if (!online) {
      error = undefined;
    }

    useDeepCompareEffectNoCheck(() => {
      if (error) {
        updateCache(undefined);
      } else if (data) {
        // Only cache the first page of infinite queries
        if (
          typeof key === "function" &&
          key().includes(USE_INFINITE_QUERY) &&
          data instanceof Array
        ) {
          updateCache(data.slice(0, 1) as unknown as ReturnType);
        } else {
          updateCache(data);
        }
      }
    }, [data, error, updateCache]);

    return {
      data,
      error,
      ...swr,
    };
  };

export const swrMiddleware: Middleware =
  <X>(useSWRNext: SWRHook) =>
  <ReturnType = X>(
    key: Key,
    fetcher: BareFetcher<ReturnType> | null,
    config: SWRConfiguration,
  ) => {
    const { online } = useNetworkInfo();
    const { data: localResult, updateCache } = useGetStoredSWRValue<ReturnType>(
      key,
      online ? 3000 : 100,
    );
    const { data, error: swrError, ...swr } = useSWRNext(key, fetcher, config);

    // if both data and localResult are undefined or null, return whatever data is
    const result = data && online ? data : localResult ?? data;
    let error = swrError;
    if (
      error instanceof FirebaseError &&
      ((error.code === "functions/internal" && error.message === "internal") ||
        error.code === "functions/deadline-exceeded")
    ) {
      // This is for cloud function network errors
      error = undefined;
    } else if (error?.name === "RetryError") {
      // This is for algolia network errors
      error = undefined;
    } else if (!online) {
      error = undefined;
    }

    useDeepCompareEffectNoCheck(() => {
      if (error) {
        updateCache(undefined);
      } else if (data && online) {
        // Only cache the first page of infinite queries
        if (
          typeof key === "function" &&
          key().includes(USE_INFINITE_QUERY) &&
          data instanceof Array
        ) {
          updateCache(data.slice(0, 1) as unknown as ReturnType);
        } else {
          updateCache(data);
        }
      }
    }, [data, error, online, updateCache]);

    const usingLocalResult = !data && localResult === result && localResult;
    useDeepCompareEffectNoCheck(() => {
      if (usingLocalResult && localResult instanceof Array) {
        localResult.forEach((doc) => {
          if (doc.path && doc.data && doc.id) {
            staticMutate(doc.path, { ...doc, s: "dc" }, false).catch(
              console.error,
            );
          }
        });
      }
    }, [usingLocalResult, localResult]);

    return {
      data: result,
      error,
      ...swr,
    };
  };

function useGetFromCloudFunction<ResultType>(
  functionName?: FunctionNameV2,
  params?: Record<string, unknown>,
  options?: SWRConfiguration,
): ReadDocumentReturnType<ResultType> {
  const { data, error, mutate } = useSWR<ResultType>(
    functionName ? { version: "v2", functionName, params } : null,
    async (fetchParam) => {
      const { functionName: fetchFunctionName, params: fetchParams } =
        fetchParam;
      try {
        return await callCloudFunctions<ResultType>(
          fetchFunctionName,
          fetchParams,
        );
      } catch (e) {
        console.error(`Error calling cloud function ${fetchFunctionName}`, e);
        throw e;
      }
    },
    options,
  );

  const revalidate = useCallback(
    async (clearResult = false) => {
      if (clearResult) {
        await mutate(undefined, clearResult);
      } else {
        await mutate();
      }
      return;
    },
    [mutate],
  );

  return {
    data,
    error,
    loading: !error && !data,
    revalidate,
  };
}

export async function callCloudFunctions<ResultType>(
  functionName: FunctionNameV2,
  params?: Record<string, unknown>,
): Promise<ResultType> {
  const fn = httpsCallable(getFunctions(), "clients-functions");

  const startTime = Date.now();
  try {
    const result = await fn({
      functionName,
      ...params,
    });
    const endTime = Date.now();
    track(TrackEvent.callCloudFunction, {
      functionName,
      time: endTime - startTime,
    });
    return result.data as ResultType;
  } catch (e) {
    track(TrackEvent.cloudFunctionError, {
      functionName,
      params: JSON.stringify(params ?? {}),
      error: JSON.stringify(e),
    });
    throw e;
  }
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function usePaginate<T extends object>(
  collection: string | null,
  converter: FirestoreDataConverter<T>,
  limit?: number,
  orderBy?: OrderByType,
  where?: WhereType,
  listen?: boolean,
): PaginatedResult<T> {
  const { data, loading, isValidating, mutate } = useCollection<T>(
    collection,
    {
      limit,
      where,
      orderBy,
      converter,
      listen,
      ignoreFirestoreDocumentSnapshotField: false,
    },
    {
      // this lets us update the local cache + paginate without interruptions
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 0,
    },
  );

  // save this data for paginate useCallback
  const [hasMore, setHasMore] = useState(limit ? true : false);

  const dataRef = useRef(data);
  if (dataRef.current !== data) {
    if ((dataRef.current?.length ?? 0) > (data?.length ?? 0)) {
      setHasMore(limit ? true : false);
    }
    dataRef.current = data;
  }

  const collectionRef = useRef(collection);
  useEffect(() => {
    if (collectionRef.current !== collection) {
      setHasMore(true);
    }
    collectionRef.current = collection;
  }, [collection]);

  const orderByRef = useRef(orderBy);
  useEffect(() => {
    orderByRef.current = orderBy;
  }, [orderBy]);

  const whereRef = useRef(where);
  useEffect(() => {
    whereRef.current = where;
  }, [where]);

  const paginatingRef = useRef(false);

  const paginate = useCallback(async () => {
    if (!dataRef.current?.length) return;

    if (!collection) return;

    if (!hasMore) return;

    if (paginatingRef.current) return;

    // get the snapshot of last document we have right now in our query
    const startAfterDocument =
      dataRef.current[dataRef.current.length - 1].snapshot;

    if (!startAfterDocument) return;

    paginatingRef.current = true;

    const query: CollectionQueryType<T> = {
      limit: limit ? limit + 1 : limit,
      where: whereRef.current,
      orderBy: orderByRef.current,
      startAfter: startAfterDocument,
    };

    // get more documents, after the most recent one we have
    const moreDocs = await getCollection<T>(collection, query, {
      converter,
      ignoreFirestoreDocumentSnapshotField: false,
    });

    if ((limit && moreDocs.length <= limit) || moreDocs.length === 0) {
      setHasMore(false);
    }

    // mutate our local cache, adding the docs we just added
    // set revalidate to false to prevent SWR from revalidating on its own
    await mutate((s) => {
      if (moreDocs.length === 0) {
        return s;
      }
      const newState = [...(s ?? [])];
      const idSet = new Set(newState.map((doc) => doc.id));
      moreDocs.forEach((doc) => {
        if (!idSet.has(doc.id)) {
          newState.push(doc);
        }
      });
      return newState;
    }, false);

    paginatingRef.current = false;
  }, [collection, converter, hasMore, limit, mutate]);

  if (!collection) {
    return {
      data: [],
      loading: false,
      hasMore: false,
      nextPage: async () => {
        // do nothing;
      },
    };
  }

  const result = data
    ?.map((doc) => {
      if (doc.data) {
        return doc.data;
      }
      return undefined;
    })
    .filter((doc): doc is T => !!doc);

  return {
    data: result,
    loading,
    hasMore: result && result.length === 0 ? false : hasMore,
    isValidating,
    nextPage: paginate,
  };
}

export function uploadFile(path: string, file: File): Promise<string> {
  const fileRef = ref(getStorage(), path);
  const newMetadata = {
    contentType: file.type,
  };
  return new Promise<string>((resolve, reject) => {
    const uploadTask = uploadBytes(fileRef, file, newMetadata);
    uploadTask.then(
      (snapshot) => {
        resolve(snapshot.ref.fullPath);
      },
      (error) => {
        console.error("uploadFile/error", error);
        reject(error);
      },
    );

    // future: allow callback to indicate progress: https://firebase.google.com/docs/storage/web/upload-files#web-version-8_6
  });
}

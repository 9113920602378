import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class UserFunding {
  id: string;
  smsPersonId?: string;
  smsCompanyId?: string;
  smsCompanyName?: string;
  /** Normalized by NORMALIZED_FUNDING_ROUNDS */
  round?: string;
  updatedAt: StorageTimestamp;
  createdAt: StorageTimestamp;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    smsPersonId?: string,
    smsCompanyId?: string,
    smsCompanyName?: string,
    round?: string,
  ) {
    this.id = id;
    this.smsPersonId = smsPersonId;
    this.smsCompanyId = smsCompanyId;
    this.smsCompanyName = smsCompanyName;
    this.round = round;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }
}

export const userFundingConverter: FirestoreDataConverter<UserFunding> = {
  toFirestoreModel: (data) => {
    return {
      id: data.id,
      sms_person_id: data.smsPersonId,
      sms_company_id: data.smsCompanyId,
      sms_company_name: data.smsCompanyName,
      round: data.round,
      created_at: data.createdAt,
      updated_at: data.updatedAt,
    };
  },
  fromFirestoreModel: (data) => {
    return new UserFunding(
      data.id,
      data.created_at,
      data.updated_at,
      data.sms_person_id,
      data.sms_company_id,
      data.sms_company_name,
      data.round,
    );
  },
};

export enum FundingRoundCode {
  A = "A",
  Acquired = "ACQUIRED",
  Angel = "ANGEL",
  B = "B",
  C = "C",
  Crowdfunding = "CROWDFUNDING",
  D = "D",
  Debt = "DEBT",
  E = "E",
  EarlyStageVc = "EARLY_STAGE_VC",
  F = "F",
  G = "G",
  Grant = "GRANT",
  H = "H",
  I = "I",
  Incubator = "INCUBATOR",
  Ipo = "IPO",
  J = "J",
  K = "K",
  LaterStageVc = "LATER_STAGE_VC",
  Other = "OTHER",
  Seed = "SEED",
  Termination = "TERMINATION",
  VcUnattributed = "VC_UNATTRIBUTED",
}

// Only include round types that follow a typical funding progression.
export const INCLUDE_ROUNDS = new Set([
  FundingRoundCode.Incubator,
  FundingRoundCode.Angel,
  FundingRoundCode.Seed,
  "Pre-seed / Seed",
  FundingRoundCode.Crowdfunding,
  FundingRoundCode.A,
  FundingRoundCode.B,
  FundingRoundCode.C,
  FundingRoundCode.D,
  "D+",
  FundingRoundCode.E,
  FundingRoundCode.F,
  FundingRoundCode.G,
  FundingRoundCode.H,
  FundingRoundCode.I,
  FundingRoundCode.J,
  FundingRoundCode.K,
  FundingRoundCode.Ipo,
  FundingRoundCode.Acquired,
  FundingRoundCode.Termination,
]);

export const TERMINAL_ROUNDS = new Set([
  FundingRoundCode.Ipo,
  FundingRoundCode.Acquired,
  FundingRoundCode.Termination,
]);

export const ROUND_DISPLAY_ORDER = Array.from(INCLUDE_ROUNDS).reduce(
  (acc, round, i) => ({
    ...acc,
    [round]: i,
  }),
  {} as Record<FundingRoundCode, number>,
);

/** Sources that can be exposed */
export const SHAREABLE_FUNDING_ROUNDS = new Set([
  "cbinsights",
  "crunchbase",
  "pitchbook",
]);

/** For search faceting, normalize rounds into one of these. */
export const NORMALIZED_FUNDING_ROUNDS: {
  [key in FundingRoundCode]:
    | FundingRoundCode // Funding round to map to
    | "D+" // VP doesn't care past D; clump them together
    | "Pre-seed / Seed" // VP wants a bunch of seed-like things together
    | undefined; // don't index
} = {
  [FundingRoundCode.Incubator]: "Pre-seed / Seed",
  [FundingRoundCode.Angel]: "Pre-seed / Seed",
  [FundingRoundCode.Seed]: "Pre-seed / Seed",
  [FundingRoundCode.A]: FundingRoundCode.A,
  [FundingRoundCode.B]: FundingRoundCode.B,
  [FundingRoundCode.C]: FundingRoundCode.C,
  [FundingRoundCode.D]: "D+",
  [FundingRoundCode.E]: "D+",
  [FundingRoundCode.F]: "D+",
  [FundingRoundCode.G]: "D+",
  [FundingRoundCode.H]: "D+",
  [FundingRoundCode.I]: "D+",
  [FundingRoundCode.J]: "D+",
  [FundingRoundCode.K]: "D+",
  [FundingRoundCode.Ipo]: FundingRoundCode.Ipo,
  [FundingRoundCode.Acquired]: FundingRoundCode.Acquired,
  [FundingRoundCode.Termination]: undefined,
  [FundingRoundCode.Crowdfunding]: undefined,
  [FundingRoundCode.Debt]: undefined,
  [FundingRoundCode.EarlyStageVc]: undefined,
  [FundingRoundCode.LaterStageVc]: "D+",
  [FundingRoundCode.VcUnattributed]: undefined,
  [FundingRoundCode.Other]: undefined,
  [FundingRoundCode.Grant]: undefined,
};

/** If we saw one of these rounds, the company can still be marked as "Pre-seed / Seed". But if the company does a "Named Round", they can no longer be marked as "Pre-seed / Seed".
 * This can happen if the company does an Incubator or Angel funding after a Series A.
 */
export const UNNAMED_ROUND = new Set([
  FundingRoundCode.Incubator,
  FundingRoundCode.Angel,
  FundingRoundCode.Seed,
  FundingRoundCode.Crowdfunding,
  FundingRoundCode.Debt,
  FundingRoundCode.Grant,
]);
